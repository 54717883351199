@import "/css/reset.scss";
@import "/css/swiper.scss";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900');
// Шрифты для девеломпента
// @import "/css/fontdev.scss";
// Шрифты для прода
@import "/css/fonts.scss";
.mobile-back,
.ScrollbarsCustom-track.ScrollbarsCustom-trackHorizontal {
    display: none;
}
.slick-arrow {
    display: none !important;
}
.container {
    padding: 0 75px 0 75px;
    width: 100%;
}
.ScrollbarsCustom-wrapper.processing{
    filter: blur(5px);
}
.App {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}
.header{
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    width: 100%;
    .container{
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    &--menu{
        display: flex;
        align-items: center;
        padding-top: 12px;
        margin-left: 215px;
        li{
            margin-right: 42px;
            a{
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: bold;
                line-height: 32px;
                font-size: 18px;
                text-align: right;
                letter-spacing: 1.1px;
                text-transform: uppercase;
                color: #FFFFFF;
                opacity: 0.9;
                position: relative;
                transition: all 0.4s;
                &:hover{
                    transition: all 0.4s;
                    letter-spacing: 0.08em;
                }
                &:after{
                    position: absolute;
                    z-index: -1;
                    top: 11px;
                    left: -9%;
                    width: 0;
                    height: 4px;
                    content: "";
                    -webkit-transition: width .4s;
                    -o-transition: width .4s;
                    transition: width .4s;
                    -webkit-transform: skew(-30deg);
                    -ms-transform: skew(-30deg);
                    transform: skew(-30deg);
                    background: #2f69ff;
                }
                &:hover:after{
                    width: 120%;
                }
            }
            .active-link{
                width: 120%;
                &:after{
                    width: 120%;
                }
            }
            &:last-child{
                margin-right: 0;
            }
        }
    }
    &--locale{
        padding-top: 18px;
        position: absolute;
        right: 80px;
        .active{
            font-weight: bold;
            font-size: 20px;
            color: #FFFFFF;
        }
        a{
            line-height: 27px;
            cursor: pointer;
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            text-align: right;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: #D8D8D8;
            opacity: 0.9;
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;
            &:hover{
                font-weight: bold;
                color: #FFFFFF;
            }
            &:nth-child(1){
                margin-right: 6px;
            }
        }

    }
}
.slick-slide,
.slick-slide>div{
    height: 100%;
    width: 100% !important;
}
.slick-arrow{
    display: none;
}
.slick-dots{
    z-index: 10;
    position: fixed;
    right: 69px;
    top: 50%;
    transform: translateY(-50%);
    .slick-active{
        button{
            font-size: 40px;
            opacity: 1;
            &:after{
                left: -10px;
            }
        }
        &:before{
            right: -85px;
            transition: all 0.5s;
        }
    }
    li{
        margin-bottom: 16px;
        text-align: right;
        position: relative;
        button{
            background: transparent;
            border: navajowhite;
            font-size: 24px;
            color: #FFFFFF;
            opacity: 0.8;
            font-family: 'PFDinTextCompPro-Regular';
            &:before{
                position: static;
                top: 1px;
                left: 1px;
                content: "0";
            }
        }
        &:before{
            content: '';
            width: 62.21px;
            height: 3px;
            position: absolute;
            right: -185px;
            z-index: 10;
            background-color: #fff;
            top: 22px;
            transform: skew(-30deg);
            transition: all 0.5s;
        }
        &:hover {
            &:before {
                right: -85px;
            }
        }
    }
}
.slides{
    height: 100vh;
    width: 100vh;
    display: block !important;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    -o-transition: transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    &--background{
        padding: 0 75px;
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        &--item{
            width: 100%;
            max-width: 295px;
            border-left: 1px solid rgba(255, 255, 255, 0.3);
        }
        &--item:last-child{
            border-right: 1px solid rgba(255, 255, 255, 0.3);
        }
    }
    &:after {
        content: '';
        background: radial-gradient(435.82px at 58.78% 50%, rgba(0, 0, 0, 0.1) 0%, #000000 100%);
        background-blend-mode: multiply;
        mix-blend-mode: multiply;
        opacity: 0.2;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        position: absolute;
        top: 0;
        bottom: 0;
    }
    &-description{
        position: absolute;
        left: 75px;
        bottom: 75px;
        z-index: 1;
        &--title{
            font-family: 'PFDinTextCompPro-Regular';
            line-height: 110px;
            font-size: 120px;
            color: #FFFFFF;
            letter-spacing: -0.6px;
        }
        &--description{
            max-width: 640px;
            margin-top: 20px;
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: normal;
            line-height: 32px;
            font-size: 22px;
            color: #FFFFFF;
        }
        &--more{
            margin-top: 45px;
            &-link{
                width: 295px;
                height: 60px;
                display: inline-block;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                line-height: 58px;
                font-size: 18px;
                text-align: center;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #FFFFFF;
                background: -webkit-gradient(linear, right top, left top, color-stop(15.03%, #2F69FF), to(#0136C0));
                background: -webkit-linear-gradient(right, #2F69FF 15.03%, #0136C0 100%);
                background: -o-linear-gradient(right, #2F69FF 15.03%, #0136C0 100%);
                background: linear-gradient(270deg, #2F69FF 15.03%, #0136C0 100%);
                position: relative;
                cursor: pointer;
                &:after{
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: -1;
                    opacity: 0;
                    background: #0435B5;
                    transition: all 0.5s;
                }
                &:hover:after{
                    z-index: 1;
                    opacity: 1;
                }
                a{
                    z-index: 10;
                    display: block;
                    height: 100%;
                    width: 100%;
                    position: relative;
                }
            }
        }
    }
    &--more__active{
        img{
            transform: rotate(45deg);
            transition: transform 0.3s;
        }
        .slides--more--btn {
            background: #2F69FF;
        }
        .slides--more--description{
            z-index: inherit;
            opacity: inherit;
            pointer-events: inherit;
            transition: all 0.4s;
        }
    }
    &--more{
        position: absolute;
        z-index: 2;
        top: 22%;
        left: calc(66% - 10px);
        &--btn{
            background: linear-gradient(270deg, #2F69FF 15.03%, #0136C0 100%);
            width: 60px;
            height: 60px;
            text-align: center;
            cursor: pointer;
            position: relative;
            img{
                margin-left: auto;
                margin-right: auto;
                transition: transform 0.3s;
                position: relative;
                top: 18px;
                z-index: 10;
            }
            &:after{
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                top: 0;
                right: 0;
                background: #0433AF;
                opacity: 0;
                transition: opacity 0.5s;
            }
            &:hover:after{
                opacity: 1;
                transition: opacity 0.5s;
            }
        }
        &--description{
            z-index: -1;
            opacity: 0;
            pointer-events: none;
            -webkit-transition: all 0.4s;
            -o-transition: all 0.4s;
            transition: all 0.4s;
            width: 295px;
            background: -webkit-gradient(linear, left bottom, left top, from(#FFFFFF), to(#FFFFFF));
            background: -webkit-linear-gradient(bottom, #FFFFFF, #FFFFFF);
            background: -o-linear-gradient(bottom, #FFFFFF, #FFFFFF);
            background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
            margin-left: 60px;
            margin-top: -60px;
            font-family: Noto Sans;
            font-style: normal;
            font-weight: normal;
            line-height: 26px;
            font-size: 14px;
            color: #5C5C5C;
            padding: 29px 31px 29px 34px;
        }
    }
    &-next-page{
        z-index: 2;
        width: 295px;
        height: 157px;
        position: absolute;
        right: 74px;
        bottom: 75px;
        padding-left: 25px;
        padding-bottom: 23px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        cursor: pointer;
        transition: transform 0.5s;
        &:after{
            content: '';
            z-index: 0;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: #000000;
            background-blend-mode: multiply;
            mix-blend-mode: multiply;
            opacity: 0.15;
        }
        &--next-number{
            font-family: 'PFDinTextCompPro-Regular';
            line-height: 43px;
            font-size: 48px;
            color: #FFFFFF;
            z-index: 1;
            position: relative;
        }
        &--next-title{
            font-family: Noto Sans;
            font-style: normal;
            font-weight: bold;
            line-height: 24px;
            font-size: 24px;
            color: #FFFFFF;
            z-index: 1;
            position: relative;
        }
        &:hover{
            transform: scale(1.1);
        }
    }
}
.slides-more-info{
    width: 975px;
    height: 100%;
    background-color: #fff;
    position: absolute;
    right: -975px;
    top: 0;
    &--wrapper{
        height: 100%;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    &-left{
        padding: 88px 0px 60px 58px;
        max-width: 335px;
        width: 100%;
        height: 100%;
        display: inline-block;
        vertical-align: top;
        &--menuList{
            padding-bottom: 6px;
            width: 245px;
            border-bottom: 2px solid rgba(0, 0, 0, 0.15);
            li{
                font-family: Noto Sans;
                font-style: normal;
                font-weight: normal;
                line-height: 32px;
                font-size: 18px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                color: #5C5C5C;
                margin-bottom: 18px;
                cursor: pointer;
                margin-left: 3px;
                ul{
                    margin-top: 11px;
                    li{
                        font-family: 'Noto Sans';
                        font-style: normal;
                        font-weight: normal;
                        line-height: 20px;
                        font-size: 14px;
                        color: #828282;
                        text-transform: none;
                        letter-spacing: 0;
                        transition: all 0.5s;
                        &:hover{
                            color: #2F69FF;
                            letter-spacing: 0.05em;
                        }
                    }
                    li{
                        a{
                            &:after{
                                display: none;
                            }
                        }
                        .active-link{
                            color: #2F69FF;
                            line-height: 20px;
                            font-size: 14px;
                            font-weight: inherit;
                            margin-left: 0;
                            &:after{
                                display: none;
                            }
                        }
                    }
                }
                .active-link{
                    font-weight: bold;
                    line-height: 32px;
                    font-size: 18px;
                    color: #333333;
                    position: relative;
                    margin-left: 5px;
                    &:after{
                        position: absolute;
                        z-index: -1;
                        top: 11px;
                        left: -6%;
                        width: 113%;
                        height: 4px;
                        content: "";
                        -webkit-transition: width .4s;
                        transition: width .4s;
                        -webkit-transform: skew(-30deg);
                        transform: skew(-30deg);
                        background: #2f69ff;
                    }
                }
                a{
                    position: relative;
                    &:hover{
                        &:after{
                            position: absolute;
                            z-index: -1;
                            top: 11px;
                            left: -6%;
                            width: 113%;
                            height: 4px;
                            content: "";
                            -webkit-transition: width .4s;
                            transition: width .4s;
                            -webkit-transform: skew(-30deg);
                            transform: skew(-30deg);
                            background: #2f69ff;
                        }
                    }
                }
            }
            .products-link{
                .active-link{
                    ul {
                        li{
                            a{
                                &:after{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        &--secondMenu{
            margin-top: 29px;
            li{
                font-family: Noto Sans;
                font-style: normal;
                font-weight: normal;
                line-height: 26px;
                font-size: 14px;
                color: #5C5C5C;
                margin-bottom: 11px;
                cursor: pointer;
                transition: all .5s;
                &:hover {
                    transition: all .5s;
                    color: #2f69ff;
                    letter-spacing: .05em;
                }
                .active-link{
                    font-weight: bold;
                    font-size: 14px;
                    color: #333333;
                    position: relative;
                    &:after{
                        position: absolute;
                        z-index: -1;
                        top: 9px;
                        left: -4%;
                        width: 110%;
                        height: 4px;
                        content: "";
                        -webkit-transition: width .4s;
                        transition: width .4s;
                        -webkit-transform: skew(-30deg);
                        transform: skew(-30deg);
                        background: #2f69ff;
                    }
                }
            }
        }
    }
    &-right{
        width: 100%;
        max-width: 616px;
        transition: all 0.7s ease-in;
        font-family: Noto Sans;
        font-style: normal;
        font-weight: normal;
        line-height: 26px;
        font-size: 14px;
        color: #222222;
        .ScrollbarsCustom-content{
            margin-top: 54px;
            padding-right: 95px;
            padding-left: 24px;
            padding-top: 16px;
        }
        .static-content{
            h2{
                margin-bottom: 10px;
            }
            p{
                margin: 25px 0;
            }
        }
        .ScrollbarsCustom-track{
            width: 1px !important;
            background: #C4C4C4 !important;
            right: 22px !important;
            overflow: visible !important;
            height: 80% !important;
            top: 140px !important;
            .ScrollbarsCustom-thumb {
                width: 3px !important;
                margin-left: -1px;
                background: #C1D3FF !important;
            }
        }
        h2{
            font-family: 'PFDinTextCompPro-Regular';
            line-height: 46px;
            font-size: 36px;
            margin-bottom: 20px;
            position: relative;
            &:after{
                content: '';
                position: absolute;
                left: -15px;
                top: 1px;
                background: #2F69FF;
                width: 21px;
                height: 4px;
                transform: rotate(-53deg) skew(-44deg);
            }
        }

        h3{
            font-family: 'PFDinTextCompPro-Regular';
            line-height: 46px;
            font-size: 36px;
            margin-bottom: 20px;
        }
        .sertificate{
            display: flex;
            overflow-x: scroll;
            margin-bottom: 45px;
            padding-bottom: 40px;
            cursor: pointer;
            img{
                margin-right: 20px;
                width: 155px;
                min-width: 155px;
                height: 100%;
                min-height: 220px;
            }
        }
        ::-webkit-scrollbar {
            width: 2px;
            height: 2px;
        }
        ::-webkit-scrollbar-track {
            background-color: #C4C4C4;
            height: 2px;
            width: 2px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            height: 4px;
            background: #2F69FF;
        }
        p{
            font-family: Noto Sans;
            font-style: normal;
            font-weight: normal;
            line-height: 26px;
            font-size: 14px;
            color: #5C5C5C;
            margin: 10px 0;
        }
        blockquote {
            position: relative;
            margin: 20px 0;
            &:after {
                content: '';
                position: absolute;
                left: -12px;
                top: -12px;
                width: 100%;
                height: 108%;
                background-image: url("images/imgWrapper.svg");
                background-size: inherit;
                background-repeat: no-repeat;
                z-index: -1;
            }
        }
        img{
            width: 100%;
            height: inherit;
        }
        strong{
            font-family: Noto Sans;
            font-style: normal;
            font-weight: bold;
            line-height: 32px;
            font-size: 22px;
            color: #222222;
            margin-bottom: 20px;
            display: block;
        }
        ul{
            li{
                font-family: Noto Sans;
                font-style: normal;
                font-weight: normal;
                line-height: 26px;
                font-size: 14px;
                margin-bottom: 16px;
                color: #5C5C5C;
                position: relative;
                &:before{
                    content: '';
                    width: 12px;
                    height: 18px;
                    position: absolute;
                    left: -22px;
                    top: 6px;
                    background-image: url("images/ulli.svg");
                }
            }
        }
        table{
            caption {
                padding-top: 8px;
            }
            caption,
            thead th{
                font-family: 'PFDinTextCompPro-Regular';
                line-height: normal;
                font-size: 24px;
                text-align: center;
                color: #F2F2F2;
                min-height: 50px;
                height: 50px;
                background: #6691FF;
                vertical-align: middle;
                border: 1px solid rgba(255, 255, 255, 0.15);
            }
            tbody{
                font-family: Noto Sans;
                font-style: normal;
                font-weight: normal;
                line-height: 26px;
                text-align: center;
                color: #5C5C5C;
                tr{
                    td{
                        font-size: 12px;
                        min-width: 50px;
                        text-align: center;
                    }
                    td:nth-child(1){
                        font-size: 14px;
                        width: 130px;
                    }
                }
                td{
                    vertical-align: center;
                    min-height: 50px;
                    height: 50px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                }
            }
        }
      .more {
        font-family: Noto Sans;
        font-weight: normal;
        font-size: 13px;
        color: #7299FF;
        border-bottom: 1px dashed rgba(160, 186, 252, 0.5);
        margin-left: 25px;
        display: inline-block;
        line-height: 16px;
        cursor: pointer;
        &:hover {
          border-bottom: none;
        }
      }
        a{
            font-family: Noto Sans;
            font-weight: normal;
            font-size: 13px;
            color: #7299FF;
            border-bottom: 1px dashed rgba(160, 186, 252, 0.5);
            &:hover {
                border-bottom: none;
            }
        }
        a ~ a{
            margin-left: 26px;
        }
        a ~ p{
            display: inline-block;
            margin: 0 0 0 10px;
        }
        .contacts{
            p{
                margin: 16px 0;
            }
        }
    }
    .close-slide{
        position: absolute;
        width: 20px;
        height: 20px;
        right: 36px;
        top: 70px;
        z-index: 1000;
        cursor: pointer;
    }
    .developer{
        position: absolute;
        bottom: 65px;
        &--text{
            font-family: 'Noto Sans';
            font-style: normal;
            font-weight: normal;
            display: inline-block;
            -webkit-transform: translate(5px,-29px);
            -ms-transform: translate(5px,-29px);
            transform: translate(5px,-29px);
            line-height: 16px;
            font-size: 12px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            color: #666666;
            transition: all 0.5s;
        }
        svg {
            transition: all 0.5s;
        }
        &:hover {
            svg{
                path {
                    fill: #093EC6;
                }
            }
            .developer--text {
                border-bottom: 1px solid #5A88FF;
                color: #5A88FF;
            }
        }
    }
}
.close-slider {
    z-index: -10;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
}
.slick-track{
  .popup-video {
    display: none;
  }
    div[data-index="0"],
    div[data-index="4"] {
        .slides {
        background-image: url("images/slide1.jpg");
            .slides-next-page{
                background-image: url("images/next1.jpg");
            }
        }
      .popup-video {
        display: block;
      }
    }
    div[data-index="1"]{
        .slides {
        background-image: url("images/slide2.jpg");
            .slides-next-page{
                background-image: url("images/next2.jpg");
            }
        }
    }
    div[data-index="2"]{
        .slides {
            background-image: url("images/slide3.jpg");
            .slides-next-page{
                background-image: url("images/next3.jpg");
            }
        }
    }
    div[data-index="3"],
    div[data-index="-1"] {
        .slides {
            background-image: url("images/slide4.jpg");
            .slides-next-page{
                background-image: url("images/next4.jpg");
            }
        }
    }
}
.app-content{
    .header,
    .slick-slider{
        transform: translateX(0);
        transition: transform 0.7s ease-in;
    }
}
.slides-more-info{
    transform: translateX(0px);
    transition: transform 0.7s ease-in;
}
.modalwin {
    height: 200px;
    width: 300px;
    border: 3px outset gray;
    background: wheat;
    top: 20%; /* отступ сверху */
    right: 0;
    left: 0;
    font-size: 14px;
    margin: 0 auto;
    z-index:2; /* поверх всех */
    display: none;  /* сначала невидим */
    position: fixed; /* фиксированное позиционирование, окно стабильно при прокрутке */
    padding: 15px;
    border: 1px solid #383838;
}
#shadow {
    position: fixed;
    width:100%;
    height:100%;
    z-index:1; /* поверх всех  кроме окна*/
    background:#000;
    opacity: 0.5; /*прозрачность*/
    left:0;
    top:0;
    z-index: 9999;
}
#shadow.zzz {
    z-index: 10000;
}
#popupWin {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 10001;
    transform: translate(-50%, -50%);
    #popupWin--img {
        max-width: 900px;
        height: 86vh;
    }
    &--close {
        width: 25px;
        height: 25px;
        position: absolute;
        right: -25px;
        top: -25px;
        cursor: pointer;
    }
  .next {
    cursor: pointer;
    width: 40px;
    position: absolute;
    right: -50px;
    top: 50%;
      display: flex;
      justify-content: center;
  }
  .prev {
    cursor: pointer;
    width: 40px;
    position: absolute;
    left: -50px;
    top: 50%;
      display: flex;
      justify-content: center;
    svg {
      transform: rotate(180deg);
    }
  }
}

.popup-video {
    cursor: pointer;
    width: 280px;
    height: 157px;
    position: absolute;
    z-index: 2;
    right: 104px;
    bottom: 75px;
    background-image: url(images/video.png);
    background-repeat: no-repeat;
    background-position: -25px 0;
    background-size: cover;
    transform: translateX(-100%);
    transition: transform 0.5s;
    &:hover {
        z-index: 3;
        transform: translateX(-100%) scale(1.1);
    }
    &:after {
        content: '';
        width: 60px;
        height: 42px;
        position: absolute;
        left: 50%;
        top: 50%;
        background: url("images/youbtn.png");
        transform: translate(-50%,-50%);
    }
}
#popupWin3 {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 10000;
    transform: translate(-50%, -50%);
    #popupWin--close2 {
        width: 25px;
        height: 25px;
        position: absolute;
        right: -25px;
        top: -25px;
        cursor: pointer;
    }
    iframe {
        max-width: 1000px;
        height: 500px;
    }
}
#popupWin2 {
    display: none;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 10000;
    transform: translate(-50%, -50%);
    &--close {
        width: 25px;
        height: 25px;
        position: absolute;
        right: -25px;
        top: -25px;
        cursor: pointer;
    }
    .sertificate {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        width: 90vw;
        img {
            width: auto;
            height: 35vh;
        }
    }
}
@media (min-width: 768px) and (orientation: landscape){
    .slides-more-info.active{
        transform: translateX(-975px);
        transition: transform 0.7s ease-in;
    }
    .slides.show-more-info{
        transform: translateX(-975px);
        transition: transform 0.7s ease-in;
    }
    .app-content.show-more-info{
        .header,
        .slick-slider{
            transform: translateX(-975px);
            transition: transform 0.7s ease-in;
            .close-slider {
                z-index: 100;
            }
        }
    }
    .products-page .slides-more-info-right .ScrollbarsCustom-track{
        right: 47px !important;
    }
    .ScrollbarsCustom-wrapper {
        margin-bottom: 54px;
    }
    .App.products-page{
        .header,
        .slick-slider{
            transform: translateX(-1295px);
            transition: transform 0.7s ease-in;
            .close-slider {
                z-index: 100;
            }
        }
        .slides-more-info{
            transform: translateX(-1295px);
            transition: transform 0.7s ease-in;
            width: 1295px;
            right: -1295px;
        }
        .slides-more-info-right {
            max-width: 960px;
            padding-bottom: 30px;
            transition: all 0.7s ease-in;
        }
    }
}
@media (min-width: 1000px) and (max-width: 1400px){
    .slides *,
    .header *,
    .slick-dots,
    .slides-more-info-left,
    .slides-more-info-right .ScrollbarsCustom-content {
        zoom: 0.9;
    }
  .slick-dots {
    right: 30px;
  }
  .header {
    .container {
      padding-left: 36px;
      padding-right: 36px;
    }
    &--locale {
      right: 38px;
    }
  }
  .slides-next-page {
    width: 240px;
    height: 135px;
    right: 35px;
    background-size: cover;
  }
  .slides-description,
  .slides-next-page {
    bottom: 40px;
  }
  .slides-more-info-right .ScrollbarsCustom-track {
    right: -10px !important;
    height: 70% !important;
    top: 105px !important;
  }
  .products-page .slides-more-info-right .ScrollbarsCustom-track {
    right: 14px !important;
  }
  .slides--more {
      left: calc(66% - 1px);
  }
    .popup-video {
        width: 204px;
        height: 115px;
        right: 57px;
        background-size: cover;
        bottom: 40px;
        zoom: 1;
    }
}
@media (min-width: 320px) and (max-width: 1200px) {
    .popup-video {
        display: none !important;
    }
    .slides-more-info-right .more{
        display: none;
    }
}
@media (max-width: 1024px) and (orientation: landscape) {
    .slides *,
    .slick-dots {
        zoom: 0.7;
    }
    .slides,
    .slides-next-page,
    .slides--more--btn,
    .slides-more-info .developer,
    .popup-video{
        display: none;
    }
    .slick-dots .slick-active button:after {
        left: -5px;
    }
    .slides-more-info-right .ScrollbarsCustom-content {
        margin-top: 0;
        padding-right: 0px;
        padding-left: 13px;
        padding-top: 16px;
    }
    .slides-more-info {
        width: 100vw;
        right: -100vw;
    }
    .header .container {
        padding-left: 37px;
        padding-right: 37px;
        padding-top: 15px;
        padding-bottom: 10px;
    }
    .header--menu {
        margin-left: -45px;
    }
    .header--menu li{
        margin-right: 30px;
        a{
            font-size: 14px;
            &:after {
                top: 16px;
            }
        }
    }
    .header--locale {
        padding-top: 13px;
    }
    .slides-more-info {
        width: 100vw;
        &-left {
            padding: 20px 0px 20px 20px;
            max-width: 185px;
            overflow: scroll;
            &--menuList {
                width: 100%;
                li {
                    line-height: 10px;
                    font-size: 10px;
                    margin-bottom: 18px;
                    margin-left: 0;
                    .active-link {
                        line-height: 10px;
                        font-size: 12px;
                        margin-left: 0;
                    }
                    ul {
                        margin-top: 10px;
                        li {
                            line-height: 16px;
                            font-size: 11px;
                            margin-bottom: 10px;
                        }
                    }
                    a{
                        &:after{
                            top: 7px !important;
                            height: 3px !important;
                        }
                    }
                }
            }
            &--secondMenu {
                margin-top: 5px;
                li {
                    font-size: 10px;
                    line-height: 20px;
                    margin-bottom: 6px;
                }
            }
        }
    }
    #root {
        .App.products-page{
            .header,
            .slick-slider{
                transform: translateX(-100vw);
                transition: transform 0.7s ease-in;
                .close-slider {
                    z-index: 100;
                }
            }
            .slides-more-info{
                transform: translateX(-100vw);
                transition: transform 0.7s ease-in;
                width: 100vw;
                right: -100vw;
            }
            .slides-more-info-right {
                max-width: 100vw;
                padding-bottom: 30px;
                transition: all 0.7s ease-in;
            }
        }
        .slides-more-info.active{
            transform: translateX(-100vw);
            transition: transform 0.7s ease-in;
        }
        .slides.show-more-info{
            transform: translateX(-100vw);
            transition: transform 0.7s ease-in;
        }
        .app-content.show-more-info{
            .header,
            .slick-slider{
                transform: translateX(-100vw);
                transition: transform 0.7s ease-in;
                .close-slider {
                    z-index: 100;
                }
            }
        }
        table{
            caption,
            thead th{
                font-size: 12px;
                min-height: 30px;
                height: 30px;
            }
            tbody{
                line-height: 26px;
                tr{
                    td{
                        font-size: 12px;
                        min-width: 30px;
                        height: auto;
                    }
                    td:nth-child(1){
                        font-size: 12px;
                        width: auto;
                    }
                }
                td{
                    min-height: 50px;
                    height: 50px;
                }
            }
        }
    }
    .slides-more-info-right {
        padding-right: 35px;
        font-size: 11px;
        line-height: 18px;
        h2 {
            line-height: 20px;
            font-size: 20px;
            margin-bottom: 15px;
            &:after {
                left: -9px;
                top: 1px;
                width: 14px;
                height: 2px;
            }
        }
        h3 {
            line-height: 15px;
            font-size: 20px;
            margin-bottom: 15px;
        }
        p {
            line-height: 18px;
            font-size: 11px;
        }
        .sertificate {
            margin-bottom: 20px;
            padding-bottom: 20px;
            img {
                margin-right: 10px;
                width: 60px;
                height: 100%;
                min-height: 100px;
            }
        }
    }
    .slides-more-info .close-slide {
        width: 15px;
        height: 15px;
        right: 15px;
        top: 15px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .slides-more-info-right .ScrollbarsCustom-track {
        width: 1px !important;
        right: -12px !important;
        top: 40px !important;
    }
    .header{
      &--logotype {
        img {
          width: 40px;
        }
      }
    .container{
      display: flex;
      justify-content: space-between;
      padding-top: 8px;
      padding-bottom: 0;
      padding-left: 27px;
      padding-right: 27px;
    }
    &--menu{
      padding-top: 0;
      margin-left: -20px;
      li{
        margin-right: 10px;
        a{
          line-height: 11px;
          font-size: 6px;
          letter-spacing: 1px;
          &:after{
            top: 4px;
            left: 0;
            width: 0;
            height: 1px;
          }
          &:hover{
            &:after {
              width: 100%;
            }
          }
        }
      }
    }
    &--locale{
      padding-top: 0;
      right: 27px;
      .active{
        font-size: 9px;
      }
      a{
        line-height: 30px;
        font-size: 9px;
        &:nth-child(1){
          margin-right: 6px;
        }
      }

    }
  }
}
@media (min-width: 769px) and (max-width: 1600px){
    .slides-more-info-left{
        padding: 25px 0 60px 58px;
    }
    .slides-more-info-left--menuList li{
        line-height: 24px;
        font-size: 16px;
        letter-spacing: .08em;
        margin-bottom: 15px;
    }
    .slides-more-info-right .ScrollbarsCustom-content{
        margin-top: 20px;
    }
    .slides-more-info .developer {
        bottom: 30px;
    }
    .slides--more{
        z-index: 3;
    }
    .header {
        &--menu{
            margin-left: 7px;
            li{
                a {
                    font-size: 16px;
                }
            }
        }
    }
    .slides{
        &-description {
            left: 35px;
            &--title{
                line-height: 70px;
                font-size: 94px;
            }
            &--description{
                line-height: 26px;
                font-size: 18px;
            }
            &--more{
                margin-top: 20px;
                &-link{
                    width: 250px;
                    height: 55px;
                    font-size: 17px;
                    line-height: 54px;
                }
            }
        }
        &--background{
            padding: 0 35px;
        }
    }
}
@media (max-width: 1024px) and (orientation: landscape){
    .slick-dots{
        right: 30px;
        .slick-active {
            button{
                font-size: 32px;
            }
            &:before{
                right: -70px;
            }
            :after{
                left: -9px;
            }
        }
        li{
            button{
                font-size: 20px;
                &:after{
                    left: 3px;
                }
            }
        }
    }
    .slides{
        &-description {
            left: 35px;
            bottom: 35px;
            &--title{
                line-height: 60px;
                font-size: 60px;
            }
            &--description{
                margin-top: 20px;
                line-height: 25px;
                font-size: 20px;
            }
            &--more{
                margin-top: 20px;
                &-link{
                    width: 225px;
                    height: 50px;
                    font-size: 16px;
                    line-height: 49px;
                    a {
                        width: 320px;
                        height: 72px;
                    }
                }
            }
        }
        &--background{
            padding: 0 35px;
        }
    }
}
@media (max-widtH: 1024px) {
    .slick-dots .slick-active button,
    .slick-dots li {
        width: auto;
    }
    .slick-dots li button:before {
        position: static;
    }
}
@media (max-width: 768px)  and (orientation: portrait){
    *{
        overscroll-behavior: none;
    }
    .slides-more-info-left--menuList li ul {
        display: none;
    }
    .header--menu * {
      zoom: 1 !important;
      font-size: 8px !important;
    }
    .header--menu li {
      margin-right: 20px;
      a:after{
        top: 5px;
      }
    }
    .header.white {
        background-color: #fff;
      transition: background-color 1s;
        oveflow: hidden;
    }
    .container{
        padding: 0 35px 0 35px;
    }
    .slick-track div[data-index="1"] .slides,
    .slick-track div[data-index="3"] .slides,
    .slick-track div[data-index="-1"] .slides {
        background-position: 70% 0;
    }
    .slick-dots{
        right: 30px;
        .slick-active {
            &:before{
                right: -70px;
            }
        }
    }
    .header{
        height: 65px;
        .container{
            padding-top: 15px;
            padding-bottom: 0px;
            position: relative;
            justify-content: flex-end;
        }
        &--logotype{
            position: absolute;
            left: 34px;
            top: 10px;
            img{
                height: 50px;
                width: inherit;
            }
        }
        &--locale{
            a{
                font-weight: bold;
                font-size: 20px;
                color: #FFFFFF;
            }
            .active{
                display: none;
            }
        }
        .burger{
            right: 0;
            margin-top: 20px;
            width: 29px;
            height: 19px;
            transition: all .1s linear;
            position: relative;
            cursor: pointer;
            transition: transform .5s;
            &-inner{
                position: absolute;
                top: 50%;
                margin-top: -2px;
                width: 100%;
                height: 3px;
                border-radius: 2px;
                background-color: #fff;
            }
            &:after,
            &:before{
                content: '';
                position: absolute;
                width: 100%;
                height: 3px;
                border-radius: 2px;
                background-color: #fff;
            }
            &:after{
                bottom: 0;
            }
            &:before{
                top: 0;
            }
            &:hover{
                transform: scale(1.2);
            }
            &-active{
                .burger-inner{
                    display: none
                }
                &:after,
                &:before {
                    background-color: #605d5d;
                }
                &:after{
                    transform: rotate(45deg);
                    top: 0;
                }
                &:before{
                    transform: rotate(-45deg);
                }
            }
        }
        &--menu{
            display: none;
        }
        &--locale{
            padding-top: 13px;
            position: absolute;
            right: 81px;
        }
    }
    .slides,
    .slides-more-info-left {
        @supports (-webkit-appearance:none) {
            height: calc(100vh - 56px);
            padding-top: 56px;
        }
        overflow: hidden;
    }
    .App {
        overscroll-behavior-y: none;
        height: calc(100vh - 56px);
        overflow: hidden;
    }
    body,
    .App {
        overscroll-behavior: contain;
    }
    .slides{
        &-next-page{
            display: none;
        }
        &-description {
            left: 35px;
            right: 35px;
            &--title{
                line-height: 70px;
                font-size: 73px;
            }
            &--description{
                line-height: 27px;
                font-size: 18px;
            }
            &--more{
                margin-top: 20px;
                &-link{
                    width: 225px;
                    height: 50px;
                    font-size: 16px;
                    line-height: 49px;
                }
            }
        }
        &--background{
            padding: 0 35px;
        }
        &--more{
            display: none;
        }
        &-more{
            &-info{
                background: transparent;
                &-left{
                    background-color: #fff;
                    position: fixed;
                    left: 100vw;
                    top: 0;
                    transform: translateX(-100vw);
                    transition: all 0.6s;
                    &__active{
                        left: calc(100vw - 335px);
                        transition: all 0.6s;
                    }
                }
                &-right{
                    background-color: #fff;
                    position: fixed;
                    left: 0;
                    top: 0;
                    transform: translateX(100vw);
                    height: 100%;
                    max-width: 100%;
                    transition: all 0.5s;
                    width: 100vw;
                    padding: 25px;
                    &__slide{
                        transform: translateX(-100vw);
                        transition: all 0.5s;
                        z-index: 9999999999999;
                    }
                    .ScrollbarsCustom-content{
                        padding-top: 0;
                    }
                    .ScrollbarsCustom-track{
                        right: -4px !important;
                    }
                    h2{
                        font-size: 40px;
                    }
                    p,
                    ul li{
                        line-height: 28px;
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .mobile-back{
        display: none;
        z-index: 2;
        position: fixed;
        top: 21px;
        right: 23px;
        width: 40px;
        height: 40px;
        background-color: #fff;
        padding-top: 11px;
        zoom: 1.2;
        &--active{
            display: block;
        }
    }
    .slick-dots li{
        width: 28px;
    }
    #popupWin {
        #popupWin--img {
            width: 450px;
            height: inherit !important;
        }
        &--close {

        }
    }
}