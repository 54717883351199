@font-face {
    font-family: 'Noto Sans';
    src: local('Noto Sans'), local('NotoSans'),
         url('/../../../fonts/notosans.woff2') format('woff2'),
         url('/../../../fonts/notosans.woff') format('woff'),
         url('/../../../fonts/notosans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Noto Sans Bold';
        src: local('Noto Sans Bold'),
             local('NotoSans-Bold'),
             url('/../../../fonts/notosansbold.woff2') format('woff2'),
             url('/../../../fonts/notosansbold.woff') format('woff'),
             url('/../../../fonts/notosansbold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
      font-family: 'PFDinTextCompPro-Bold';
      src: url('/../../../fonts/PFDinTextCompPro-Bold.woff') format('woff'),
           url('/../../../fonts/PFDinTextCompPro-Bold.ttf') format('truetype'),
           url('/../../../fonts/pfdintextcomppro-bold.eot'),
           url('/../../../fonts/pfdintextcomppro-bold.woff2') format('woff2'),
           url('/../../../fonts/pfdintextcomppro-bold.eot?#iefix') format('embedded-opentype');
      font-weight: normal;
      font-style: normal;
}
@font-face {
  font-family: 'PFDinTextCompPro-Regular';
  src: url('/../../../fonts/PFDinTextCompPro-Regular.woff') format('woff'),
       url('/../../../fonts/PFDinTextCompPro-Regular.ttf') format('truetype'),
       url('/../../../fonts/pfdintextcomppro-regular.eot'),
       url('/../../../fonts/pfdintextcomppro-regular.woff2') format('woff2'),
       url('/../../../fonts/pfdintextcomppro-regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
